 .App{
  display: flex;
 }
 .pages{
  width: 100%;
  padding-left: 250px;
  transition: all 0.5s ease;
 }
 .pages-change{
    width: 100%;
    padding-left: 80px;
    transition: all 0.5s ease;
 }