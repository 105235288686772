.ViewTransaction{
    margin-top: 6rem;
}
.ViewTransaction .container .d-flex{
    justify-content: space-between;
    align-items: center;
}
.ViewTransaction .container .UpperSec{
    display: flex;
    justify-content: flex-start;
}

.ViewTransaction .container .UpperSec p{
    background-color: #9dc913;
    color: white;
}
@media screen and (max-width:700px) {
    .ViewTransaction .container .UpperSec{
        justify-content: center;
    }
    
}