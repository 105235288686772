@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:wght@300;400;500;700;900&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
:root{
    --color1: #002E5B;
    --color2: #9BC516;
}
body{
    font-family: "Roboto", "Montserrat", sans-serif;
}
a{
    color: unset;
    text-decoration: none;
}
li{
    list-style-type: none;
}
p{
    text-align: justify;
}
.nav-item .nav-link{
    color: var(--color1);
    font-family: "Roboto";
    font-size: 17px;
    font-weight: 600;
}
.nav-item .nav-link.active{
  color: var(--color2);
}
.dropdown-item.text-dark-emphasis{
  font-size: 13px;
  
}
.dropdown-item:focus, .dropdown-item:hover {
  color: #1e2125;
  background-color: #fff;
}
.drop-box{
  display: none;
}
.drop-list:hover .drop-box{
  display: flex;
}

.login-btn{
  background-color: var(--color2);
  border-radius: 18px 18px 0 18px;
}
.banner{
    height: 500px;
}
.banner video{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.banner::after{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(30, 28, 27, 0.6);
    left: 0;
    top: 0;
}
.banner h1{
    font-size: "Montserrat";
    font-size: 44px;
    font-weight: 700;
    line-height: 44px;
    backdrop-filter: blur(10px);
}
.banner .banner-content p{
    font-family: "Montserrat";
    font-size: 18px;
    line-height: 26px;
    font-weight: 500;
    backdrop-filter: blur(10px);
}
.banner .form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 350px;
    padding: 20px;
    border-radius: 20px;
    position: relative;
    background-color: rgba(30, 28, 27, 0.6);
    color: #fff;
    border: 1px solid #333;
  }
  
  .banner .title {
    font-size: 28px;
    font-weight: 600;
    letter-spacing: 1px;
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 30px;
    color: var(--color2);
  }
  
  .banner  .title::before {
    width: 18px;
    height: 18px;
  }
  
  .banner .title::after {
    width: 18px;
    height: 18px;
    animation: pulse 1s linear infinite;
  }
  
  .banner .title::before,
  .banner .title::after {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    border-radius: 50%;
    left: 0px;
    background-color: var(--color2);
  }
  
  .banner .message, 
  .banner .signin {
    font-size: 14.5px;
    color: rgba(255, 255, 255, 0.7);
  }
  
  .banner .signin {
    text-align: center;
  }
  
  .banner .signin a:hover {
    text-decoration: underline var(--color2);
  }
  
  .banner .signin a {
    color: #00bfff;
  }
  
  .banner  .flex {
    display: flex;
    width: 100%;
    gap: 6px;
  }
  
  .banner .form label {
    position: relative;
  }
  
  .banner .form label .input,
  .banner .form label textarea{
    background-color: #333;
    color: #fff;
    width: 100%;
    padding: 15px 05px 05px 15px;
    outline: 0;
    border: 1px solid rgba(105, 105, 105, 0.397);
    border-radius: 10px;
  }
  
  
  .banner .input {
    font-size: medium;
  }
  
  .banner .submit {
    border: none;
    outline: none;
    padding: 10px;
    border-radius: 10px;
    color: #fff;
    font-size: 16px;
    transform: .3s ease;
    background-color: var(--color2);
  }
  
  .elementor-1071 .elementor-element.elementor-element-e4130df > .elementor-shape-top .elementor-shape-fill {
    fill: #9BC616;
}
.banner svg {
    transform: rotate(180deg);
    height: 94px;
}
  .svg-box{
    z-index: -1;
  }
  .heading{
    color: var(--color1);
  }
  .hover_card {
    width: 100%;
    height: 180px;
    border-radius: 40px 40px 0px 40px;
    font-size: 16px;
    position: relative;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    transition: all ease 0.5s;
  }
  .hover_card:hover{
    transform: translateY(-15px);
  }
 .hover_card .icon {
    margin: 0 auto;
    width: 100%;
    height: 80px;
    max-width: 80px;
    background-color: #fff;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.8s ease;
    background-position: 0px;
    background-size: 200px;
    position: absolute;
    top: -2%;
    left: 50%;
    transform: translateX(-50%);
   
  }
  
 .hover_card .icon img {
    width: 100%;
    height: 100%;
    overflow: hidden;
    object-fit: contain;
    border-radius: 100%;
  }
  
  .hover_card .title {
    width: 100%;
    margin: 0;
    text-align: center;
    margin-top: 10px;
    color: white;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  
  .hover_card .text {
    width: 80%;
    margin: 0 auto;
    font-size: 13px;
    text-align: center;
    margin-top: 20px;
    color: white;
    font-weight: 200;
    letter-spacing: 2px;
   
  }
  .heading span{
    color: var(--color2);
  }
  .about-para strong{
    background-color: var(--color2);
   
  }

  .hover_card:hover .icon {
    background-position: -120px;
    transition: all 0.3s ease;
  }
  
  .hover_card:hover .icon svg path {
    fill: url('#gradientColor');
    transition: all 0.3s ease;
  }
  .count-box{
    background-color: var(--color1);
    width: 50px;
    height: 44px;
  }
  .para_box{
    background-color: rgba(230, 240, 202, 1);
    font-size: 14px;
    border-radius: 20px 20px 0 20px;
    height: 90px;
    display: flex;
    align-items: center;
    
  }
  .para_box p{
  width: 80%;
    
  }
  .para_box .count-box  {
    position: absolute;
    top: -16%;
    right: 0;
    width: 60px; 
    height: 60px;
    background-color: rgba(54, 1, 102, 1); 
   border-radius: 0px 20px 0px 20px;
}
.para_box .count-box::before{
  content: "";
  position: absolute;
  background-color: rgba(54, 1, 102, 1);
  width: 60px;
  height: 17px;
  top: 0px;
  left: -22%;
  border-radius: 18px 20px 0px 0px;
}
  .para_box2{
    border-radius: 20px 20px 20px 0px;
    justify-content: end;
  }
  .para_box2 .count-box  {
    position: absolute;
    top: -16%;
    left: 0;
    width: 60px; 
    height: 60px;
    background-color: rgba(54, 1, 102, 1); 
   border-radius: 20px 20px 20px 0px;
}
  .para_box2 .count-box::before{
    content: "";
    position: absolute;
    background-color: rgba(54, 1, 102, 1);
    width: 60px;
    height: 17px;
    top: 0px;
    left: 22%;
    border-radius: 18px 20px 0px 0px;
  }
  @media screen and (min-width: 900px) {
    .last_para_box1{ 
      position: absolute;
      right: -24%;
    }
    .last_para_box2{
      position: absolute;
      left: -24%;
    }
  }
  .video-box{
    /* border: 2px solid red; */
    width: 400px;
  }
  
  footer{
    background-color: var(--color1);
  }
  .social-icon{
    color: var(--color1);
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }
  .wallet {
    --bg-color: #9BC516;
    --bg-color-light: #fff;
    --text-color-hover: #fff;
    --box-shadow-color: #ebf6c6;
  }
  
  .wallet {
    width: 100%;
    height: 300px;
    background: #fff;
    border-top-right-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    box-shadow: 0 14px 26px rgba(0, 0, 0, 0.04);
    transition: all 0.3s ease-out;
    text-decoration: none;
  }
  
  .wallet:hover {
    transform: translateY(-5px) scale(1.005) translateZ(0);
    box-shadow: 0 24px 36px rgba(0, 0, 0, 0.11),
      0 24px 46px var(--box-shadow-color);
  }
  
  .wallet:hover .overlay {
    transform: scale(5) translateZ(0);
  }
  
  .wallet:hover .circle {
    border-color: var(--bg-color-light);
    background: var(--bg-color);
  }
  
  .wallet:hover .circle:after {
    background: var(--bg-color-light);
  }
  
  .wallet:hover p {
    color: var(--text-color-hover);
  }
  
  .wallet p {
    font-size: 17px;
    color: #4c5656;
    margin-top: 30px;
    z-index: 1000;
    transition: color 0.3s ease-out;
  }
  
  .circle {
    width: 131px;
    height: 131px;
    border-radius: 50%;
    background: #fff;
    border: 2px solid var(--bg-color);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    transition: all 0.3s ease-out;
  }
  
  .circle:after {
    content: "";
    width: 118px;
    height: 118px;
    display: block;
    position: absolute;
    background: var(--bg-color);
    border-radius: 50%;
    top: 7px;
    left: 7px;
    transition: opacity 0.3s ease-out;
  }
  
  .circle .circle-icon {
    z-index: 10000;
    transform: translateZ(0);
    font-size: 36px;
    color: var(--color1);
  }
  
  .wallet .overlay {
    width: 118px;
    position: absolute;
    height: 118px;
    border-radius: 50%;
    background: var(--bg-color);
    top: 70px;
    left: 50%;
    z-index: 0;
    transition: transform 0.3s ease-out;
    transform: translateX(-50%);
  }
  .contact-banner{
    height: 160px;
  }
  @keyframes pulse {
    from {
      transform: scale(0.9);
      opacity: 1;
    }
  
    to {
      transform: scale(1.8);
      opacity: 0;
    }
  }
.mid-banner{
  min-height: 550px;
  background: url(../images/bg3.jpg);
  background-position: bottom right;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
.mid-banner::before{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.25);
 
}
 .green-txt{
  color: var(--color2);
}
.mid-banner h2{
  font-size: 42px;
  backdrop-filter: blur(2px);
}
.mid-banner p{
  backdrop-filter: blur(2px);
  font-size: 17px;
}
.overlay-shadow{
  background-color: rgba(0, 0, 0, 0.24);
  display: flex;
  display: none;
}
.latest-update:hover .overlay-shadow{
  display: flex;
}
.border-line{
  width: 80px;
  height: 6px;
  background-color: var(--color2);
}
.last-banner{
  height: 340px;
  background-image: linear-gradient(180deg, #7AA100 0%, #DDFF73 100%);
}
.last-banner img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.contact-header{
  background-color: var(--color1) !important;
}

.contact-form-body{
  background-color: var(--color2);
}
.test-img::before{
  content: "";
  width: 140px;
  height: 110px;
  background-color: var(--color2);
  position: absolute;
  top: 50%;
  left: -10%;
  border-radius: 15px;
}
.swiper-button-next, 
.swiper-button-prev{
  top: 9% !important;
  background-color: var(--color1);
  color: #fff !important;
  width: 45px !important;
  height: 45px !important;
  border-radius: 50% !important;
}
.swiper-button-next::after, 
.swiper-button-prev::after{
  font-size: 20px !important;
}
.swiper-button-prev{
  left: 80% !important;
}
.winner-section .swiper-button-next, 
.winner-section .swiper-button-prev{
  top: 85% !important;
  background-color: var(--color2) !important;
  color: #fff !important;
  border-radius: 4px !important;
  
}
.winner-section .swiper-button-prev{
  left: 90% !important;
}
.strategy-box .card{
  position: absolute;
}
.strategy-box:nth-of-type(odd) .card{
  left: 10%;
  text-align: end;
  background-color: var(--color1);
width: 20vw;
}
.strategy-box:nth-of-type(odd) h2::after{
  content: "";
  position: absolute;
  height: 2px;
  width: 10%;
  background-color: grey;
  right: 52%;
  top: 50%;
  transform: translateY(-50%);
}
.strategy-box:nth-of-type(even) h2::after{
  content: "";
  position: absolute;
  height: 2px;
  width: 10%;
  background-color: grey;
  left: 52%;
  top: 50%;
  transform: translateY(-50%);
}
.strategy-box:nth-of-type(even) .card{
  right: 10%;
  background-color: var(--color1);
  width: 20vw;
}
.search-box,
.category-box{
  background-color: #F2F2F2;
}
input{
  outline: none;
}
.category-box:hover::before{
  content: "";
  position: absolute;
  width: 4px;
  height: 100%;
  left: 0;
  top: 0;
  background-color: var(--color2);
}
.latest-category p{
  font-size: 15px;
  color: var(--color1);
}
.gallery-title{
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 999;
}
.questions label{
  font-size: 14px;
}
.steps{
  display: flex;
  justify-content: center;
 position: relative;
  
}
.step-area{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px 25px;
}
.step-area span:nth-child(2){
 margin: 0 20px;
 font-size: 13px;
}
.step-area .box{
  z-index: 999;
  background-color: #eaeaea;
  margin: 0 20px;
  width: 35px;
  height: 35px;
  line-height: 35px;
  border-radius: 50%;
  text-align: center;
  border: 1.3px solid #adacac;
  
}
 .steps .step-arrow{
  position: absolute;
  top: 42%;
  height: 3px;
  width: 40%;
  background-color: #c0bebe;
}
.step-area .active-box{
  background-color: var(--color1);
  color: #ffffff;
  border: 1.5px solid var(--color1);
}
.wealth-calculator form{
  background-color: #F8F8F8;
}
.wealth-calculator form label{
  font-size: 17px;
  margin-bottom: 5px;
}
.action-plan{
  background-color: #ECF0F5;
  height: auto;
 
}
.action-plan label{
  font-size: 14px !important;
}
.form-box button{
  background-color: var(--color2);
  color: var(--color1);
}
.divider-box{
  background-color: #C9F7B2;
  height: 40px;
}
.faq-container h2{
  background-color: #EBF4EA;
}
.accordion-button.collapsed{
  color: var(--color1);
  font-weight: bold;
  background-color: #fff;
  margin-bottom: 10px;
  box-shadow: none !important;
}
.accordion-button:not(.collapsed),
.accordion-item:last-of-type>.accordion-collapse {
  background-color: #fff !important;
  box-shadow: none !important;

}
.accordion-flush>.accordion-item{
  box-shadow: 0px 9px 4px 0px rgba(138, 137, 138, 0.66);
  margin-bottom: 8px;
  border-radius: 12px !important;
  border-left: 6px solid var(--color1);
}
h3 strong{
  font-size: 46px;
  color: #006400;
}
input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  width: 90%;
  height: 8px;
  background: #579AF6;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  z-index: 0;
  border-radius: 5px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 22px;
  height: 22px;
  background: #fff;
  cursor: pointer;
  position: relative;
 z-index: 99;
 border: 1px solid #579AF6;
 border-radius: 50%;
}

.sm-text{
  font-size: 12px;
}
.masonry img {
  width: 100%;
}

.masonry {
	column-count: 4;
	column-gap: 16px;
}

.masonry .mItem {
  display: inline-block;
  margin-bottom: 16px;
  width: 100%;
}
.main-image{
  height: 260px;
}
.three-body {
  --uib-size: 35px;
  --uib-speed: 0.8s;
  --uib-color: #5D3FD3;
  position: relative;
  display: inline-block;
  height: var(--uib-size);
  width: var(--uib-size);
  animation: spin78236 calc(var(--uib-speed) * 2.5) infinite linear;
 }
 
 .three-body__dot {
  position: absolute;
  height: 100%;
  width: 30%;
 }
 
 .three-body__dot:after {
  content: '';
  position: absolute;
  height: 0%;
  width: 100%;
  padding-bottom: 100%;
  background-color: var(--uib-color);
  border-radius: 50%;
 }
 
 .three-body__dot:nth-child(1) {
  bottom: 5%;
  left: 0;
  transform: rotate(60deg);
  transform-origin: 50% 85%;
 }
 
 .three-body__dot:nth-child(1)::after {
  bottom: 0;
  left: 0;
  animation: wobble1 var(--uib-speed) infinite ease-in-out;
  animation-delay: calc(var(--uib-speed) * -0.3);
 }
 
 .three-body__dot:nth-child(2) {
  bottom: 5%;
  right: 0;
  transform: rotate(-60deg);
  transform-origin: 50% 85%;
 }
 
 .three-body__dot:nth-child(2)::after {
  bottom: 0;
  left: 0;
  animation: wobble1 var(--uib-speed) infinite
     calc(var(--uib-speed) * -0.15) ease-in-out;
 }
 
 .three-body__dot:nth-child(3) {
  bottom: -5%;
  left: 0;
  transform: translateX(116.666%);
 }
 
 .three-body__dot:nth-child(3)::after {
  top: 0;
  left: 0;
  animation: wobble2 var(--uib-speed) infinite ease-in-out;
 }
 
 @keyframes spin78236 {
  0% {
   transform: rotate(0deg);
  }
 
  100% {
   transform: rotate(360deg);
  }
 }
 
 @keyframes wobble1 {
  0%,
   100% {
   transform: translateY(0%) scale(1);
   opacity: 1;
  }
 
  50% {
   transform: translateY(-66%) scale(0.65);
   opacity: 0.8;
  }
 }
 
 @keyframes wobble2 {
  0%,
   100% {
   transform: translateY(0%) scale(1);
   opacity: 1;
  }
 
  50% {
   transform: translateY(66%) scale(0.65);
   opacity: 0.8;
  }
 }
.blog-card-body{
  height: 140px;
} 
.lazy-load-image-background{
  width: 100%;
}
.why-us-round{
  background-color: rgba(225, 244, 244, 1);
  width: 220px;
  height: 220px;
  border-radius: 50%;
}
.why-us-round img{
  width: 160px;
  color: rgba(238, 161, 3, 1);
}
.offer-card{
  border-radius: 50%;
  width: 220px;
  height: 200px;
  overflow: hidden;
}
.offer-card1{
  background-color: rgba(35, 110, 150, 1);
}
.offer-card2{
  background-color: rgba(10, 87, 208, 1);
}
.offer-card3{
  background-color: rgba(156, 196, 45, 1);
}
.offer-card::before{
  content: "";
  position: absolute;
  width: 140px;
  height: 190px;
  border-radius: 50%;
  top: 16%;
  opacity: 0.4;
  right: -24%;
}
.offer-card::after{
  content: "";
  position: absolute;
  width: 130px;
  height: 100px;
  border-radius: 50%;
  top: -18%;
  right: -18%;
 opacity: 0.4;
}
.offer-card.offer-card1::before,
.offer-card.offer-card1::after{
  background-color: rgba(60, 154, 205, 1);
}
.offer-card.offer-card2::before,
.offer-card.offer-card2::after{
  background-color: rgba(63, 134, 247, 1);
}
.offer-card.offer-card3::before,
.offer-card.offer-card3::after{
  background-color: rgba(236, 255, 184, 0.4);
}
.strategy-banner{
  width: 100%;
 height: 400px;
 background-image: url(../images/backgroundimage.png);
 background-position: center;
 background-size: cover;
 background-repeat: no-repeat;
}
.strategy-banner h1{
  letter-spacing: 70px;
  font-size: 66px;
}
.strategy-banner img{
  object-fit: cover;
}
.strategy-banner span{
  background: linear-gradient(306.72deg, #EEA005 13.46%, rgba(221, 227, 218, 0) 92.68%);
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.strategy-banner span:nth-child(odd){
  bottom: 0%;
}
.strategy-banner span:nth-of-type(1){
  left: 22.4%;
}
.strategy-banner span:nth-of-type(2){
  left: 32.7%;
}
.strategy-banner span:nth-of-type(3){
  left: 41.2%;
}
.strategy-banner span:nth-of-type(4){
  left: 52%;
}
.strategy-banner span:nth-of-type(5){
  left: 62%;
}
.strategy-banner span:nth-child(even){
  top: 0;
}
.strategy-banner span:nth-of-type(odd)::before{
  content: "";
  position: absolute;
  width: 2px;
  height: 68px;
  background-color: var(--color2);
  top: 100%;
}
.strategy-banner span:nth-of-type(even)::before{
  content: "";
  position: absolute;
  width: 2px;
  height: 88px;
  background-color: var(--color2);
  top: -87%;
}
.about-para{
  line-height: 40px;
}
.green-bg{
  background-color: var(--color2);
}
::-webkit-scrollbar {
  width: 10px; 
}

::-webkit-scrollbar-thumb {
  background-color: #888; 
  border-radius: 6px; 
  border: 3px solid #f1f1f1;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #454545;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 6px;
}
.sebi-btn{
  background-color: var(--color1);
}
.sebi-btn:hover{
  background-color: var(--color2);
}
.protfolio-bottom{
  background-color: var(--color2);
}
.lets-connect{
  background-color: #1e2125;
  color: #fff;
}
.lets-connect:hover{
  color: #1e2125;
  background-color: #fff;
}
.our-team h5{
  color: var(--color2);
}
.team-img-box{
  height: 180px;
}
.team-img-box,
.team-img-box img{
  border-radius: 0px 30px 0 30px;
  overflow: hidden;
}
.team-img-box .hover-content{
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(253,79,2,.6);
  border-radius: 0px 30px 0 30px;
  transform: translateY(100%);
  transition: all ease 0.5s;
}
.team-img-box:hover .hover-content{
  transform: translateY(0%);
}
.team-detail p,
.team-blog{
  font-size: 14px;
}
.read-more-btn{
  border: 1.4px solid var(--color2);
  color: var(--color2);
}
.bagger_card{
  position: relative;
  height: 80px;
  border: none;
  border-bottom: 3px solid rgb(209, 209, 209);
  border-left: 5px solid rgb(209, 209, 209);
  overflow: hidden;
}
.bagger_card:last-of-type{
  border: none;
  border-left: 5px solid rgb(209, 209, 209);
}
.bagger_card::before{
  content: "";
  width: 100%;
  height: 100%;
  background-color: var(--color1);
  position: absolute;
  border-radius: 0 6px 6px 0;
  top: 0;
  left: 0;
  transform: translateY(-100%);
  transition: all ease 0.5s;
}
.bagger_card.active-bg::before{
  transform: translateY(0%);
}
.active-bg{
  border-left: 5px solid green;
  border-bottom: 0;
}
.active-bg:last-of-type{
  border: none;
  border-left: 5px solid green;
}
.active-bg h6{
  color: #fff;
 
}
.bagger-img{
  height: 0px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out, height 0.5s ease-in-out;
}
.bagger-img.active {
  height: 500px;
  opacity: 1;
  visibility: visible;
}

.holding-text span{
  font-size: 16px;
  position: relative;
  z-index: 2;
}
.holding-text span:first-of-type{
  font-size: 12px;
  margin-bottom: 3px;
}
.holding-text{
 position: absolute;
  right: 0%;
  display: flex;
  justify-content: end;
  align-items: end;
  flex-direction: column;
  width: 120px;
}
.holding-text::before{ 
  content: "";
  position: absolute;
  background-image: url(../images/Rectangle26293.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  top: 0;
  right: -18%;
  width: 100%;
  height: 100%;
}
.holding-text::after{
  content: "";
  width: 6px;
  height: 36px;
  position: absolute;
  top: 0%;
  right: -3%;
  background-color: rgba(15, 127, 18, 1);
  border-radius: 0 0 10px 0;
}
.card-header{
  background-color: var(--color1);
}
.card-box p:first-child{
  font-size: 15px;
}
.card-box ul li{
  font-size: 14px;
}
.card-box{
  height: 290px;
}
.port-text{
  background-color: #cce484 !important;
}

.green-arrow{
  top: -100%;
 left: 12%;
}
.green-arrow_one{
  top: -100%;
  left: 20%;
}
.green-arrow-2{
  top: -100%;
  right: 13%;
  transform: rotateY(180deg);
}
.green-arrow_two{
  top: -100%;
  right: 21%;
  transform: rotateY(180deg);
}
.hidden-gem::before{
  content: "";
  position: absolute;
  top: 50%;
  left: 28%;
  width: 5px;
  height: 60px;
  background-color: #059a05;
  transform: translateY(-50%);
}

.green-text{
  color: var(--color2);
}
.portfolio-section{
  height: 480px;
  background-color: var(--color2);
  background-image: url(../images/Mask\ group.png);
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;
}
.orange-bg{
  background: rgba(237, 160, 6, 1);
}
.major::after{
  content: "";
  position: absolute;
  width:88%;
  height: 1.5px;
  background-color: #000;
  bottom: 0;
  left: 0;
}
.purple{
  color: rgba(95, 2, 179, 1);
  font-size: 18px;
}
.graph-box span{
  font-size: 13px;
  z-index: 9999;
  width: 35%;
  top: -18%;
}

.consists li{
  font-size: 14px;
}
.portfolio_structure_section{
  min-height: 450px;
  background-image: url(../images/banner1.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.graph-text{
  left: 15%;
}
.graph-text-two{
  right: 15%;
   top: 28%;
}
.stock-overview-section{
  height: 600px;
  background-image: url(../images/banner2.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
}
.stock-overview-section img{
  width: 100%;
  height: 100%;
  max-width: 1200px;
}
.stock-selection-section{
  width: 900px;
  height: 350px;
}
.stock-selection-section img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.graph_small_text{
  font-size: 13px;
}
.pointer{
  cursor: pointer;
}
.site_logo_container{
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.investement-card{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  text-decoration: none;
  z-index: 0;
  border-radius: 5px;
  background: var(--color1);
  color: #fff;
  transition: all ease 0.4s;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  height: 120px;
  border-radius: 10px;
  border: 2px solid var(--color1);
  width: 260px;
  margin: auto;
}

.card-title-container{
  text-align: center;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  font-size: 20px;
  margin-bottom: 15px;
  border: 2px solid var(--color1);
  position: absolute;
  top: -20%;
}
.benifit-card{
  background-color: #fff;
  border-radius: 10px;
  padding:  5px;
  padding-top: 20px;
  height: 180px;
  box-shadow: 0px 8px 8.5px 0px rgba(91, 88, 88, 0.25);
}

.benifit-card .check-box{
  border-radius: 50%;
  width: 80px;
  height: 80px;
  padding: 4px;
  background-color: rgba(229, 233, 218, 1);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.benifit-card p{
  padding-left: 20px;
  font-size: 14px;
  text-align: center;
}
.investement-card p{
  padding-left: 5px;
  font-size: 15px;
}
.equity-card{
  position: relative;
  background-color: #ffffff;
  height: 200px;
  border-radius: 10px;
  padding: 2em 1.2em;
  text-decoration: none;
  border: 1.5px solid rgb(214, 214, 214);
  overflow: hidden;
  cursor: pointer;
  border-top: 4px solid var(--color2);
}
.equity-card::before{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 200px;
  background-color: var(--color1);
  border-radius: 10px;
  z-index: 1;
  transform: translateY(-100%);
  transition: all ease 0.5s;
  
}
.equity-card:hover::before{
  transform: translateY(0%);
}

.equity-card:hover  p,
.equity-card:hover h5{
  color: #ffffff;
}
.alternate-investement{
  background: transparent;
  background-image: url(../images/AIF.png);
  background-repeat: no-repeat;
  background-position: center;
  height: 480px;
  background-size: cover;
 
}
.equity-advisory{
background-image: url(../images/Equity\ Advisory.png);
}
.col-md-6 h3 span{
  color: var(--color2);
}
.fund_benefits_section{
  min-height: 300px;
}
.fund_benefits{
  background-color: rgba(229, 233, 218, 1);
  height: 200px;
}
.advantage_fund{
  background-color: rgba(21, 36, 43, 1);
}
.investment_philosophy{
  background-color: rgba(229, 233, 218, 1);
}
.our_approach{
 background: rgba(240, 246, 221, 1);
 
}
.approach-card{
  background-color: var(--color2);
  color: #fff;
  height: 80px;
 width: 80px;
 margin: auto;
 border-radius: 12px;
box-shadow: 5px 6px 4px -1px rgba(0, 0, 0, 0.25);

}
.approach-card_img{
  position: absolute;
  background-color: rgba(80, 89, 51, 1);
  border-radius: 12px;
  box-shadow: 5px 6px 4px -1px rgba(0, 0, 0, 0.25);
  top: -20%;
  right: -10%;
}
.inv_card1{
  background-color: rgba(94, 99, 79, 1);
}
.inv_card2{
  background-color: rgba(107, 114, 85, 1);
}
.inv_card3{
  background-color: rgba(126, 135, 98, 1);
}
.inv_card4{
  background-color: rgba(142, 153, 108, 1);
}
.inv_card5{
  background-color: rgba(158, 171, 116, 1);
}
.login-dropdown{
  width: 165px;
  height: 100px;
  top: 100%;
  right: 0%;
  background-color: #FFF;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  z-index: 9999;
}
.detail_table tr{
  font-size: 14px;
}

@media (max-width: 1199px) {
  .masonry {
    column-count: 3;
  }
}

@media (max-width: 991px) {
  .masonry {
    column-count: 2;
  }
}

@media (max-width: 767px) {
  .masonry {
    column-count: 1;
  }
  .fund_benefits_section {
    min-height: 700px;
    height: auto;
}
.alternate-investement{
  height: 200px;
}
}
@media (min-width: 767px) {
  .test_para{
    width: 75%;
  }
  .drop-box{
    width: 600px;
    left: 50%;
  }
}

  @media screen and (max-width: 768px) {
    .banner{
        min-height: 740px;
    }
   
    .strategy-banner{
      width: 100%;
      height: 300px;
    }
    .winner-section .swiper-button-prev{
      left: 35% !important;
    }
    .winner-section .swiper-button-next{
      right: 35% !important;
    }
    .video-box {
     
      width: 100%;
  }
  .offer-card {
   
    width: 300px;
   
}
.offer-card::before {
 
  width: 100px;
  
  right: -7%;
}
.offer-card::after {
 
  width: 70px;
 
  right: -6%;
 
}
.portfolio-img{
  width: 100%;
  height: 100%;
}
.stock-triangle,
.stock-triangle::before,
.stock-triangle_two::before,
.stock-triangle_three::before{
  width: 100%;
}
.green-arrow{
 left: 0%;
}
.green-arrow_one{
  left: 11%;
}
.green-arrow-2{
  right: 0%;
}
.green-arrow_two{
  right: 11%;
}
.stock-triangle span{
  font-size: 13px;
}
.card-box{
  height: auto;
}
.port-text{
  font-size: 16px;
}
.holding-text::before{
  right: -10%;
}
.holding-text::after{
  right: 1%;
}
.graph-text{
  left: 1%;
}
.graph-text-two{
  right: 1%;
}
.stock-selection-section{
  width: 100%;
  height: 300px;
}
  }

  
