.profile-img{
    height: 75px;
    width: 75px;
    background-color: #fff;
    border-radius: 50%;
    border: 4px solid #5A4343;
}
.profile-img img{
    border-radius: 50%;
}
.upload-profile{
    background-color: #9dc913;
   color: #fff;
    padding: 5px; 
    border-radius: 4px;
    cursor: pointer;
}
.update-btn{
    background-color: #5A4343;
    color: #fff;
    border-radius: 4px;
    border: none;
}
.save-change-btn{
    background-color: #9dc913;
    color: #fff;
    border-radius: 4px;
    border: none;
}