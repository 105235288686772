.form-screen {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    width: 490px;
    background-color: #fff;
    border-radius: 12px;
    padding: 20px;
    position: absolute;
    bottom: -10%;
    left: 50%;
    transform: translate(-50%, -20%);
    z-index: 999;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 4px 0px inset;
    transition: all 0.5s ease;
  }
  .agreement-otp-screen{
    bottom: -33%;
  }
  .form-screen .title {
    font-size: 20px;
    font-weight: bold;
    color: black
  }
  
  .form-screen .message {
    color: #a3a3a3;
    font-size: 14px;
    margin-top: 4px;
    text-align: center
  }
  
  .form-screen .inputs {
    margin-top: 10px
  }
  
  .form-screen .inputs input {
    width: 32px;
    height: 32px;
    text-align: center;
    border: none;
    border-bottom: 1.5px solid #9dc913;
    margin: 0 10px;
  }
  
  .form-screen .inputs input:focus {

    outline: none;
  }
  
  .form-screen .action {
    margin-top: 24px;
    padding: 12px 16px;
    border-radius: 8px;
    border: none;
  background-color: #9dc913;
    color: white;
    cursor: pointer;
    align-self: end;
  }
  .form-screen .action.me-2{
    background-color: #5a4343;
  }
  .text-danger{
    font-size: 14px;
  }
  .modal-container-body::-webkit-scrollbar{ 
    width: 8px;
    height: 30px;  
  }
  .modal-container-body::-webkit-scrollbar-thumb{
    background-color: #9dc913;
    border-radius: 18px;
  }
  .modal-container-body::-webkit-scrollbar-track{
   box-shadow: inset 0 0 6px #d1ec80;
    border-radius: 18px;
  }
  @media screen and (max-width: 770px) {
    .form-screen{
      width: 80vw;
      bottom: -40px;
      padding: 20px 10px;
    }
    .form-screen .inputs input{
      margin: 0 5px;
    }
  }
  