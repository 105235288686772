.PortfolioReview{
    margin-top: 6rem;
}
.PortfolioReviewHeader{
    background-color: #5A4344;
    color: white;
    border-bottom: 6px solid rgb(42, 202, 36);
}
.PortfolioReviewHeader h1 {
     font-weight: bold;
 
 }

 .ActionIcon {
     justify-content: flex-start;
     display: flex;
     align-items: center;
 }

 .ActionIcon .fa-file-pen {
     color: rgb(181, 224, 51);
 }

 .ActionIcon .fa-file-pen:hover {
     color: rgb(133, 170, 23)
 }
 

 .ActionIcon .fa-trash-can {
     color: #5A4344;
 }

 .ActionIcon .fa-trash-can:hover {
     color: #5a4344b0;
 }

 .ActionIcon .fa-solid:hover {
     transform: scale(0.7);
     transition: 0.7s;
     cursor: pointer;
 }
 
 @media screen and (max-width:500px) {
    .PortfolioReviewHeader{
        width: 100%;
        margin: 0 !important;
        height: 40vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .PortfolioReviewTable{
        overflow: scroll;
        width: 95%;
        margin: 0;
        
    }
    .PortfolioReview{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    }

 }