.NavBar {
    display: flex;
    justify-content: space-between;
    background-color: #ffffff;
    box-shadow: 2px 2px 15px rgb(223, 220, 220);
    position: fixed;
    top: 0;
    z-index: 2;
    width: calc(100% - 250px);
    transition: all 1ms ease;
}

.Navbar-change{
    width: calc(100% - 85px) ;
    transition: all 3ms ease;
}

.NavBar .CompanyLogo {
    width: 150px;
    height: 80px;
    overflow: hidden;
    margin-left: 10px;
}

.NavBar .CompanyLogo img {
    height: 100%;
    width: 100%;
}

.MessageLogOutOption {
    display: flex;
    justify-content: space-around;

    align-items: center;

}

.MessageLogOutOption .Message,
.LogOut {
    border-radius: 50%;
    background-color: #5A4343;
    color: white;
    padding: 4px;

}

.MessageLogOutOption .Message:hover {
    background-color: #ffffff;
    color: #5A4343;
    cursor: pointer;
}

.MessageLogOutOption .LogOut:hover {
    background-color: #ffffff;
    color: #5A4343;
    cursor: pointer;
}
.menu-toggle{
    display: none;
}
@media screen  and (max-width: 768px){
    .NavBar{
        width: calc(100% - 90px);
    }
    .Navbar-change{
        width: 100%;
    }
    .menu-toggle{
        display: initial;
    }
}
.modal-wrapper{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(48, 48, 48, 0.8);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
}
.logout-confirm{
    background-color: #ffffff;
    border-radius: 6px;
    text-align: center;
    padding: 30px;
    border-bottom: 10px solid #5A4343;
}
.logout-confirm .body span{
    display: block;
    font-size: 30px;
    color: #ffffff;
    background-color: #5A4343;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: auto;
}
.logout-confirm .body p{
    font-size: 18px;
    margin-top: 15px;
}
.logout-confirm .bottom-buttons{
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
}
.logout-confirm .bottom-buttons button{
    border: none;
    padding: 7px 30px;
    border-radius: 4px;
    color: #ffffff;
}
.logout-confirm .bottom-buttons .no{
   background-color: #5A4343;
}
.logout-confirm .bottom-buttons .yes{
   background-color: #9DC913;
}

footer{
    background-color: #5A4343;
    height: 70px;
    color: #ffffff;
}