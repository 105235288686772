.BillsInvoice{
    margin-top: 6rem;
}
.BillsInvoice .BillsInvoiceHeader {
    border-bottom: 6px solid rgb(42, 202, 36);
    background-color: #585858;
}

.BillsInvoice .BillsInvoiceHeader .card {
    background-color: rgb(157, 201, 19);
    color: rgb(0, 0, 0);
    box-shadow: 0px 2px 2px 2px rgb(70, 69, 69);
}

.BillsInvoice .BillsInvoiceHeader .card .Pending {
    display: flex;
    font-weight: 500;
}

.BillsInvoice .BillsInvoiceHeader .card .Pending:hover span {
    transform: scale(1.1);
    cursor: pointer;
    transition: 0.4s;
}


.BillsInvoice .BillsInvoiceHeader .card {
    padding: 20px;
}

.BillsInvoiceHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
}

.BillsInvoiceHeader button {
    background-color: rgb(157, 201, 19);
    font-weight: 500;
    color: white;

}

.BillsInvoiceHeader button:hover,
button:focus {
    box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
    transform: translateY(-0.25em);
}

.BillsInvoiceTable .ActionIcon .fa-solid {
    color: rgb(107, 235, 75);
}

.BillsInvoiceTable {
    overflow: scroll;
    height: fit-content;
}

.BillsInvoiceTable .DownloadIcon .fa-solid {
    color: #5A4344;

}

.BillsInvoiceTable .DownloadIcon:hover span {
    border-bottom: 1px solid;
    cursor: pointer;
}

.BillsInvoiceTable .DownloadIcon:hover .fa-solid {
    transform: scale(0.7);
    transition: 0.7s;
    cursor: pointer;
}
.verticle-table input{
    width: 60%;
}
@media screen and (max-width:500px) {
    .BillsInvoiceHeader {
        flex-direction: column;
        width: 100%;
        margin: 0 !important;
        height: 40vh;
    }

    .BillsInvoice .BillsInvoiceHeader .card {
        background-color: rgb(73, 73, 73);
        align-items: center;
        color: white;

    }
}