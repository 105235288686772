.PortfolioStrategy{
    margin-top: 6rem;
}
.PortfolioStrategy .card{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

}
.PortfolioStrategy .UpperBtn {
    display: flex;
    justify-content: right;
}
.PortfolioStrategy .UpperBtn Button{
    margin-right: 8px;
}
.PortfolioStrategy .PortfolioStrategy3{
    display: flex;
    flex-direction:column;
}
.PortfolioStrategy .PortfolioStrategy3 .d-flex{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}
 
.PortfolioStrategChart {
  
    display: flex;
    justify-content: center;
}
.PortfolioStrategChart  .PortfolioStrategChartCanvasJSChart{
    width: 80%;
   
}
.strategy-img{
    width: 180px;
}
.strategy-img img{
    width: 100%;
    height: 100%;
}
@media screen and (max-width:540px) {
    .PortfolioStrategy .PortfolioStrategy2 h2, h3{
        font-size:large;
  
    }
    .PortfolioStrategy .PortfolioStrategy2{
        flex-direction: column;
        align-items: flex-start;
    }
    .PortfolioStrategy .PortfolioStrategy3 .d-flex{
        justify-content: center;
    }
    .PortfolioStrategy .PortfolioStrategy3 .d-flex img{
        width: 200px;
    }
  
}