.ManagersDesk {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 6rem;
    
}
.ManagersDesk .container{
    box-shadow: 2px 2px 10px 0px rgb(196, 194, 194);
    width: auto;
    border-radius: 10px;
}

.ManagersDesk .container h3 {
    color: #5A4343;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.ManagersDesk .container h4 {
    color: #8FB21E;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.ManagersDesk .container p {
  text-align: justify;
}

  .ManagersDeskVideo{
    width:"300px";
    height: 200px;
    display: flex;
    overflow: hidden;
    flex-direction: row;
}

.ManagersDeskVideo .ManagersDeskVideos .HoverVideoPlayer{
    width: 100%;
    height: 100%;
 
}
.ManagersDeskVideo{
    z-index: 0;
}
@media screen and (max-width:900px) {
    .ManagersDesk .container{
        padding-top: 20px;
    }
    .ManagersDesk .container h3 {
        font-size: 20px;
    }
 .ManagersDesk .ManagersDeskVideo{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: auto;
        margin-left: -40px;
       
    }
    .ManagersDeskVideo .ManagersDeskVideos .HoverVideoPlayer{
        /* width: 300px !important; */
    }
}