.HomeCard {
    width: 100%;
    margin-top: 100px;
}

.HomeCard .col-md {
    margin-top: 10px;
}

.HomeCard .row .col-xl-4 .card,
.HomeCard .row .col-xl-3 .card {
    height: 130px;
    background-color: #fff;
    border-radius: 10px;
    margin: 6px;
    border: none;
    position: relative;
    margin-bottom: 30px;
    box-shadow: 0 0.46875rem 2.1875rem rgba(90, 97, 105, 0.1), 0 0.9375rem 1.40625rem rgba(90, 97, 105, 0.1), 0 0.25rem 0.53125rem rgba(90, 97, 105, 0.12), 0 0.125rem 0.1875rem rgba(90, 97, 105, 0.1);
}

.HomeCard .row .col-xl-3 .row-1 {
    border-bottom: 14px solid #9DC913 !important;
}

.HomeCard .row .col-xl-3 .row-2 {
    border-bottom: 14px solid #5A4343 !important;
}

.card .card-statistic-3 {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.card .mx-4 .card-title {
    color: #898988;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.card .mx-4 {
    margin: 20px;
}

.mx-4 .Values {
    color: #08512B;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.static-1 {

    background-image: url("../../Components/photos/clarity_rupee-solid.png");
    background-repeat: no-repeat;
    background-position: right;

}

.static-2 {
    background-image: url("../../Components/photos/Vector.png");
    background-repeat: no-repeat;
    background-position: right;
}

.static-3 {
    background-image: url("../../Components/photos/game-icons_sands-of-time.png");
    background-repeat: no-repeat;
    background-position: right;
}

.static-4 {
    background-image: url("../../Components/photos/material-symbols_humidity-percentage.png");
    background-repeat: no-repeat;
    background-position: right;
}

.static-5 {
    background-image: url("../../Components/photos/fluent_money-24-filled.png");
    background-repeat: no-repeat;
    background-position: right;
}

.static-6 {
    background-image: url("../../Components/photos/clarity_rupee-solid.png");
    background-repeat: no-repeat;
    background-position: right;
}

/* .l-bg-cherry {
    background: linear-gradient(to right, #493240, #f09) !important;
    color: #fff;
}

.l-bg-blue-dark {
    background: linear-gradient(to right, #373b44, #4286f4) !important;
    color: #fff;
}

.l-bg-green-dark {
    background: linear-gradient(to right, #0a504a, #38ef7d) !important;
    color: #fff;
}

.l-bg-orange-dark {
    background: linear-gradient(to right, #a86008, #ffba56) !important;
    color: #fff;
}

.card .card-statistic-3 .card-icon-large .fas, .card .card-statistic-3 .card-icon-large .far, .card .card-statistic-3 .card-icon-large .fab, .card .card-statistic-3 .card-icon-large .fal {
    font-size: 110px;
}

.card .card-statistic-3 .card-icon {
    text-align: center;
    line-height: 50px;
    margin-left: 15px;
    color: #000;
    position: absolute;
    right: -5px;
    top: 20px;
    opacity: 0.1;
}

.l-bg-cyan {
    background: linear-gradient(135deg, #289cf5, #84c0ec) !important;
    color: #fff;
}

.l-bg-green {
    background: linear-gradient(135deg, #23bdb8 0%, #43e794 100%) !important;
    color: #fff;
}

.l-bg-orange {
    background: linear-gradient(to right, #f9900e, #ffba56) !important;
    color: #fff;
}

.l-bg-cyan {
    background: linear-gradient(135deg, #289cf5, #84c0ec) !important;
    color: #fff;
} */


/* .PieChart-Table .PieChart{
 
    padding: 10px;
    overflow: hidden !important;
}

 .PieChart-Table   .DashBoardTable{
 border-radius: 10px;
 overflow: scroll;
 box-shadow: 0px 2px 20px 2px rgb(204, 202, 202);
 
 
 } */


.PieChartTitle {
    justify-content: space-between;
    align-items: center;

}
 
.PieChart-Table {
    display: flex;
 
    justify-content: space-between;
}
.DashBoardTable {
    height: auto;
    overflow: scroll;
}
.PieChart-Table .DashBoardTable .table {
    font-weight: 400;
}
.PieChart-Table .DashBoardTable .table {
    font-size: 14px;
}
.pagination {
   
    display: flex;
    justify-content: center;
    list-style-type: none;
    padding: 0;
  }
  
  .pagination li {
    padding: 4px 8px;
    border: 1.4px solid rgb(202, 202, 202);
  }
  
  .pagination li a {
    text-decoration: none;
    color: #000;
  }
  
  .pagination li.active a {
    font-weight: 600;
    color: #007bff;
  }
  .new_feature{
    top: -10%;
    right: -5%;
    font-size: 14px;
  }
  .blur-text {
    filter: blur(3px);
  }
  .view_btn{
    background-color: rgb(157, 201, 19);
  padding: 5px 10px;
  border-radius: 4px;
  color: white;
  }
@media screen and (max-width:1200px) {
   
    .HomeCard .row .col-xl-4 .card .mx-4{
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .HomeCard .row .col-xl-4 .card .mx-4 h2{
        font-size: 20px;
    }
    .PieChart-Table {
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }
    .PieChart-Table .DashBoardTable{
        margin-top: 20px;
    }
    .PieChart-Table .PieChart {
        height: fit-content
    }

    .PieChart-Table .PieChart, .DashBoardTable {
        width: 100%;
    }

    .PieChartTitle {
        flex-direction: column;
    }

    
}