/* Google Font Link */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins" , sans-serif;
}
 
.SideBar .sidebar{
 height: 100vh;
  left: 0;
  width: 260px;
  background: #9DC913;
  padding: 6px 14px;
  transition: all 0.5s ease;
  position: fixed;
  z-index: 3;
overflow-y: auto;
}
.SideBar .open{
  width: 85px;
}
.SideBar .sidebar .logo-details{
  height: 140px;
  display: flex;
  width: 100%;
  border-radius: 10px;
  align-items: center;
  position: relative;
  background-color: #1d1b31;
  justify-content: center;
 margin-top: 20px;
  
}
.SideBar .sidebar .logo-details .profile-image{
  border: 5px solid #5A4343;
  width: 90px;
  height: 90px;
  border-radius: 50%;
 margin-bottom: -50px;
  overflow: hidden;
}
.SideBar .sidebar .logo-details .profile-image img{
  width: 100%;
  height: 100%;
border-radius: 50%;
object-fit: cover;
 
}
.SideBar .sidebar .logo-details .icon{
  opacity: 0;
  transition: all 0.5s ease;
}
.SideBar .sidebar .logo-details .logo_name{
  color: #fff;
  font-size: 17px;
  font-weight: 500;
  opacity: 1;
  transition: all 0.5s ease;
}
.sidebar.open .logo-details .icon,
.sidebar.open .logo-details .logo_name{
  opacity: 0;
}
.sidebar .logo-details #btn{
  position: absolute;
  top: 40%;
  right: 0;
  transform: translateY(-50%);
  font-size: 28px;
  transition: all 0.4s ease;
  
  text-align: center;
  cursor: pointer;
  transition: all 0.5s ease;
}
.sidebar.open .logo-details #btn{
  top: 50%;
  text-align: right;
  margin-right: 15px;
}
.sidebar i{
  color: #fff;
  height: 60px;
  min-width: 50px;
  font-size: 28px;
  text-align: center;
  line-height: 60px;
}
.sidebar .nav-list{
  margin-top: 50px;
  height: auto;
}
.sidebar li{
  position: relative;
  margin: 8px 0;
  list-style: none;
}
.sidebar li .tooltip{
  position: absolute;
  top: -20px;
  left: calc(100% + 15px);
  z-index: 999;
  background: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 400;
  white-space: nowrap;
  pointer-events: none;
  transition: 0s;
  display: none;
}
.sidebar li:hover .tooltip{
  pointer-events: auto;
  transition: all 0.4s ease;
  top: 50%;
  transform: translateY(-50%);
  /* opacity: 1; */
}

.sidebar input{
  font-size: 15px;
  color: #FFF;
  font-weight: 400;
  outline: none;
  height: 50px;
  width: 100%;
  width: 50px;
  border: none;
  border-radius: 12px;
  transition: all 0.5s ease;
  background: #1d1b31;
}
.sidebar.open input{
  padding: 0 20px 0 50px;
  width: 100%;
}
.sidebar .bx-search{
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-size: 22px;
  background: #1d1b31;
  color: #FFF;
}
.sidebar.open .bx-search:hover{
  background: #1d1b31;
  color: #FFF;
}
.sidebar .bx-search:hover{
  background: #FFF;
  color: #11101d;
}
.sidebar li a{
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 12px;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
  background:#7B9628;
}
.sidebar li a:hover{
  background: #5A4343;
}
.sidebar li a.active{
  background: #5A4343;
}
.sidebar li a .links_name{
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  white-space: nowrap;
  opacity: 1;
  pointer-events: none;
  transition: 0.4s;
}
.sidebar.open li a .links_name{
  display: none;
  pointer-events: auto;
}
.sidebar li a:hover .links_name,
.sidebar li a:hover i{
  transition: all 0.5s ease;
 
}
.sidebar li i{
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  border-radius: 12px;
}
.sidebar li.profile{
  position: fixed;
  height: 60px;
  width: 78px;
  left: 0;
  bottom: -8px;
  padding: 10px 14px;
  background: #1d1b31;
  transition: all 0.5s ease;
  overflow: hidden;
}
.sidebar.open li.profile{
  width: 250px;
}
.sidebar li .profile-details{
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}
.sidebar li img{
  height: 45px;
  width: 45px;
  object-fit: cover;
  border-radius: 6px;
  margin-right: 10px;
}
.sidebar li.profile .name,
.sidebar li.profile .job{
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  white-space: nowrap;
}
.sidebar li.profile .job{
  font-size: 12px;
}
.sidebar .profile #log_out{
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background: #1d1b31;
  width: 100%;
  height: 60px;
  line-height: 60px;
  border-radius: 0px;
  transition: all 0.5s ease;
}
.sidebar.open .profile #log_out{
  width: 50px;
  background: none;
}
.home-section{
  position: relative;
  background: #E4E9F7;
  min-height: 100vh;
  top: 0;
  left: 78px;
  width: calc(100% - 78px);
  transition: all 0.5s ease;
  z-index: 2;
}
.sidebar.open ~ .home-section{
  left: 250px;
  width: calc(100% - 250px);
}
.home-section .text{
  display: inline-block;
  color: #11101d;
  font-size: 25px;
  font-weight: 500;
  margin: 18px
}
::-webkit-scrollbar{ 
  width: 4px; 
}
::-webkit-scrollbar-thumb{
  background-color: var(--color6);
  border-radius: 18px;
}
::-webkit-scrollbar-track{
 /* box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3); */
  border-radius: 18px;
}

.nav-sub-items{
  transition: 0.3s;
  padding-left: 1rem;
}
.nav-sub-items-change{
  transition: 0.3s;
  padding-left: 0rem;
}

@media screen and (max-width: 768px) {
  .SideBar .sidebar{
     width: 85px;
  }
     .SideBar .open{
      margin-left: -100px;
      width: 0;
    } 
   .sidebar li a .links_name,
   .SideBar .sidebar .logo-details .profile-image,
   .SideBar .sidebar .logo-details p{
    display: none;
   }
   .pages{
    padding-left: 90px;
   }
   .pages-change{
    padding-left: 0;
   }
}
 