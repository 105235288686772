.Support{
  margin-top: 6rem;
}
.Support .support1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 

}

.Support .support1 .support1card {
  margin-bottom: 2rem;
  width: 100%;


}

.Support .support1 .support1card .row .col-sm-6 .card {
  background: #ffffff;
  color: rgb(78, 79, 80);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  border-radius: 15px;

}

.Support .support1 .support1card .row .col-sm-6 .card:hover {
  transition: 1s !important;
  background-color: rgba(29, 27, 49, 0.863);
  color: rgb(235, 235, 235);
  transform: scale(1.05);

}

.Support .support1 .support1card .row .col-sm-6 .card .card-body {

  width: 70%;
  display: flex;
  justify-content: space-between;


}
.support2card{
  margin-bottom: 40px;
}
.supportHeadImage {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  background-color: #6d6c6c;
  border-bottom: 6px solid rgb(42, 202, 36);
}
.supportHeadImage h2 {
  font-weight: bold;
  color: #ffffff;
}

.supportHeadImage img {
  border-radius: 50%;
  width: 70px;
}

.support2card {
  border: 1px solid rgba(0, 0, 0, 0.171);
  border-radius: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #ffffff;

}

.support2card .support2cardHead {
  color: #6d6c6c;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Kameron;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.support2cardform {
  color: rgb(75, 72, 72);
  width: 40%;
}

.support2cardform .mb-3 input {
  background-color: rgb(255, 255, 255);


}

.support2cardform .mb-3 textarea {
  background-color: rgb(255, 255, 255);
}

.support2cardform button {
  background-color: rgb(157, 201, 19);
  font-weight: 500;
  color: white;
  width: 100%;
}

.support2cardform button:hover {
  background-color: white;
  color: black;
  outline: 2px solid rgb(157, 201, 19);
}
/* From Uiverse.io by ahmed150up */ 
.chat-card {
  width: 300px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}

.chat-header {
  background-color: #333;
  color: #fff;
  padding: 10px;
  font-size: 18px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.chat-window {
  height: 250px;
  overflow-y: scroll;
}

.message-list {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.chat-input {
  display: flex;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #ccc;
  margin-top: 10px;
}

.message-input {
  flex: 1;
  border: none;
  outline: none;
  padding: 5px;
  font-size: 14px;
  
}

.send-button {
  border: none;
  outline: none;
  background-color: #333;
  color: #fff;
  font-size: 14px;
  padding: 5px 10px;
  cursor: pointer;
}

.send-button:hover {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.25);
}
.send-button:disabled{
  cursor: not-allowed;
  background-color: #c0c0c0;
}
.reciver{
  justify-content: end;
}
.chat-date{
  font-size: 10px;
}
@media screen and (max-width:1200px) {
  .support2card {
    flex-direction: column;
  }

  .support2cardform {
    width: 90%;
  }
  .Support .support1{
  margin: 0 !important;
  }
  .support2card .support2cardHead {
    font-size: xx-large;
  }

  .Support .support1 .support1card .row .col-sm-6 .card .card-body {
    flex-direction: column;
    text-align: center;
  }
}

@media screen and (max-width:570px) {
  .Support .support1 .support1card .row .col-sm-6 {
    margin-bottom: 20px;
  }
  .supportHeadImage{
    margin: 0 !important;
    width: 100% !important;
    flex-direction: column;
    height: 30vh;
    margin-bottom: 30px !important;
  }
 .card  .card-title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
 

}