  /* .static-7{
    background-image: url("../../photos/Vector1.png");
    background-repeat: no-repeat;
    background-position: right;
 
}

  .static-8{
    background-image: url("../../photos/uil_money-insert.png");
    background-repeat: no-repeat;
    background-position: right;
} */

.ProfileDetail{
  margin-top: 6rem;
}
  .ProfileDetail .row .col-6 .ProfileDetail-card {
    box-shadow: 0px 2px 15px 2px rgb(226, 226, 226);

  }

  .ProfileDetail .row .col-6 .ProfileDetail-card .mx-4 h2 {
    font-weight: bold;
    color: #1d1b31;
  }

  .ProfileDetail-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .ProfileDetail-card img {
    width: 150px;
    height: 150px;
    margin-right: 20px;

  }
  
  .download-btn{
    background-color: #ffc107;
    border: none !important;
    outline: none !important;
  color: #ffffff;
  padding: 5px ;
  border-radius: 4px;
  }
  .download-btn:hover{
    background-color: #f0c441;
  }

  .portfolio-detail-table {

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .portfolio-detail-table .detail-table {
    width: 55vw;
    text-align: center;
  }
  .portfolio-detail-table .detail-table-change{
    width: 45vw;
    text-align: center;
  }
.view-btn a{
  font-size: 18px;
}
.table-input input{
  width: 60px;
}
.table-btn{
  background-color: #5A4343;
  color: #fff;
  border: none;
  padding: 5px 8px;
  border-radius: 4px;
  font-size: 13px;
}
.qualitative-table{
  border: 1.5px solid #000 !important;
  text-align: center !important;
}
.qualitative-table-head{
  border-right: 1.5px solid #fff !important;
  text-align: center !important;
}
.qualitative-table-head{
  border-left: 1.5px solid #000 !important;
}
.qualitative-table-head:last-child{
  border-right: 1.5px solid #000 !important;
}

  @media screen and (max-width:1250px) {
    .ProfileDetail .row .col-md-6 {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
    }

    .ProfileDetail .row .col-md-6 .card .mx-4 h2 {
      font-size: 26px;
    }

    .ProfileDetail .row .col-md-6 .card .mx-4 h5 {
      font-size: 15px;
    }

  }

  @media screen and (max-width:500px){
    .ProfileDetail-card img {
      width: 100px;
      height: 100px;
      margin: 0;
    }
    .ProfileDetail .row .col-md-6 .card .mx-4 h2 {
      font-size: 20px;
    }

    .ProfileDetail .row .col-md-6 .card .mx-4 h5 {
      font-size: 15px;
    }
    .ProfileDetail .UpperBtn{
      justify-content: center !important;
    }
    .portfolio-detail-table .detail-table {
      width: 90vw;
      
    }
    .portfolio-detail-table .detail-table-change{
      width: 78vw;
   
    }

  }
  @media screen and (max-width:370px){
    
    .ProfileDetail .row .col-md-6 .card .mx-4 h5 {
      font-size: 12px;
    }
    .ProfileDetail-card img {
      width: 70px;
      height: 70px;
      margin: 0;
    }
  }