.Modal-card {
    width: 100%;
    max-width: 400px;
    min-width: 200px;
    height: auto;
    background-color: #ffffff;
    margin: 10px;
    padding: 15px 0;
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.24);
    border: 2px solid rgba(7, 7, 7, 0.12);
    font-size: 16px;
    transition: all 0.3s ease;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
  }
  
  .Modal-card .icon {
    margin: 0 auto;
    width: 100%;
    height: 80px;
    max-width: 80px;
    background: linear-gradient(90deg, #7eaaff 0%, #ff48fb 40%, rgba(0, 0, 0, 0.28) 60%);
    border-radius: 100%;
    font-size: 34px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.8s ease;
    background-position: 0px;
    background-size: 200px;
  }
  
  
  .Modal-card .text {
    width: 90%;
    margin: 0 auto;
    font-size: 14px;
    text-align: center;
    margin-top: 20px;
    color: #000;
    font-weight: 400;
    letter-spacing: 2px;
    transition: all 0.3s ease;
  }
  .Modal-card .button.is-primary{
    padding: 10px 25px;
  border-radius: 8px;
  background-color: transparent;
  border: 0;
  font-weight: 600;
  cursor: pointer;
  transition: 0.15s ease;
  background-color: #9dc913;
  margin-top: 15px;
  color: #ffffff;
  
}
@media screen and (max-width: 500px) {
  .Modal-card{
    width: 90vw;
  }
}