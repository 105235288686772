.TransactionUpload {
    margin-top: 6rem;
    width: 100%;
}
 .TransactionUpload button{
    background-color: rgb(157,201,19);
    font-weight: 500;
    color: white;
 }
 .TransactionUploadHead{
    background-color: rgb(210,254,255);
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid black;
 }
 .uploaddocument button:hover{
    background-color: white;
    color: black;
    outline:2px solid rgb(157,201,19);
 }
 .AdvisedSubmitbtn{
    display: flex;
   justify-content: center;
    align-items: center;

}
.col-md-8{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 20px 5px rgb(231, 230, 230);
}

.TransactionUploadAdvised .row .col-md-8 form{
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.transection-img{
    width: 70px;
}
.transection-img img{
    width: 100%;
    height: 100%;
}
@media screen and (max-width:1150px) {
    
    .TransactionUploadAdvised .row{
     margin: 0;
        justify-content: center;
     
    }
    
    .TransactionUploadAdvised .row .col-md-8{
        /* width: 90%; */
        margin: 0;
    }
    .TransactionUploadAdvised .row .col-md-8 h4 {
        margin: 20px;
    }
    .TransactionUploadAdvised .row .col-md-3{
        /* width: 90%; */
        display: flex;
        margin-top: 50px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
  .uploaddocument{
    /* width: 100%; */
    
  }
  .uploaddocument1{
    margin-top: 0 !important;
  }
    .uploaddocument form{
        display: flex;
        justify-content: center;
        flex-direction: column;
        
    }
  
}