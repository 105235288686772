.sign-up{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
}
.sign-up .form {
  background-color: #ffffff;
  border-radius: 20px;
  box-sizing: border-box;
  height: auto;
  padding: 20px;
  width: 500px;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.sign-up .title {
  color: #000;
  font-family: sans-serif;
  font-size: 30px;
  font-weight: 600; 
}
.first-screen{
  position: relative;
}
.sign-up .subtitle {
  color: #000;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.sign-up .input-container {
  height: 40px;
  position: relative;
  width: 100%;
}

.sign-up .ic1 {
  margin-top: 15px;
}

.sign-up .ic2 {
  margin-top: 15px;
}

.sign-up .input {
  border-radius: 12px;
  border: 0;
  box-sizing: border-box;
  font-size: 16px;
  height: 100%;
  outline: 0;
  padding: 4px 20px 0;
  width: 100%;
  border-bottom: 2px solid #cfe48e;
}

.sign-up .cut {
  background-color: #15172b;
  border-radius: 10px;
  height: 20px;
  position: absolute;
  top: -20px;
  transform: translateY(0);
  transition: transform 200ms;
  width: -webkit-fill-available;
}

.sign-up input::placeholder {
  color: #65657b;
  font-family: sans-serif;
  line-height: 7px;
  
 
}
.sign-up .submit {
  background-color: #9dc913;
  border-radius: 22px;
  border: 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  padding: 6px 35px;
  text-align: center;
  text-transform: uppercase;
  display: block;
  margin: auto;
  margin-top: 20px;
}

.sign-up .submit:active {
  background-color: #7ca10f;
}
.sign-up input:-internal-autofill-selected{
  background-color: #fff;
}
.second-screen{
  height: 520px;
}
.objective-content{
  background-color: #ffffff;
  border-radius: 20px;
  box-sizing: border-box;
  height: auto;
  padding: 30px 20px;
  width: 450px;
  height: auto;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.objective-content h4{
  font-size: 22px;
  color: #000;
}
.objective-content.third{
  width: 560px;
  height: auto;
}
.objective-desc{
  font-size: 13px;
  color: red;
  text-align: justify;
}
.objective-body{
  height: auto;
  color: #000;
}
.objective-options label{
 font-size: 16px;
  
}
.objective-options{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.objective-options input{
  width: 15%;
  height: 30px;
  border: none;
  border: 2px solid #000;
  border-radius: 2px;
}
.objective-options input:focus {
  outline: none;
}

.third-container {
  color: #000;
}
sup{
  color: red;
}
.third-container label {
  font-weight: bold;
  width: 35%;
  /* display: block; */
  margin-bottom: 10px;
  cursor: pointer;
}
.third-container .score-label{
  width: 100%;
  font-size: 15px;
  font-weight: 500;
  color: #65657b;
  
}
.third-container .score-label span{
  font-size: 22px;
  font-weight: 600;
  margin-left: 15px;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.third-container .inpdddut[type="file"] {
  padding: 10px;
  margin-bottom: 15px;
  border: none;
  /* background-color: #303245; */
  border-radius: 5px;
  width: 65%;
  cursor: pointer;
  border-bottom: 2px solid #9dc913;
  cursor: pointer;
}

.third-container .inpdddut[type="submit"] {
  padding: 10px 20px;
  background-color: #303245;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.third-container .inpdddut[type="submit"]:hover {
  background-color: #006F8F;
}
.third-container .terms-condition{
  width: 70%;
  text-align: right;
}

.third-container .inpdddut[type="checkbox"]{
  width: 20px;
  height: 20px;
  
}
.modal-box .button,
.input,
.select,
.textarea {
  font: inherit;
}

.modal-box a {
  color: inherit;
}

.modal-container {
  max-height: 600px; 
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  background-color: #ffffff;
  color: #000;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.25);
}


.modal-container-header {
  padding: 16px 32px;
  border-bottom: 1px solid #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.modal-container-title {
  display: flex;
  align-items: center;
  gap: 8px;
  line-height: 1;
  font-weight: 700;
  font-size: 1.125;
}

.modal-container-title .file-img{
 font-size: 24px;
  color: #000;
}

.modal-container-body {
  padding: 24px 32px 51px;
  overflow-y: auto;
}

.rtf h1,
.rtf h2,
.rtf h3,
.rtf h4,
.rtf h5,
.rtf h6 {
  font-weight: 700;
}

.rtf h1 {
  font-size: 1.5rem;
  line-height: 1.125;
}

.rtf h2 {
  font-size: 1.25rem;
  line-height: 1.25;
}

.rtf h3 {
  font-size: 1rem;
  line-height: 1.5;
}

.rtf > * + * {
  margin-top: 1em;
}

.rtf > * + :is(h1, h2, h3) {
  margin-top: 2em;
}

.rtf > :is(h1, h2, h3) + * {
  margin-top: 0.75em;
}

.rtf ul,
.rtf ol {
  margin-left: 20px;
  list-style-position: inside;
}

.rtf ol {
  list-style: numeric;
}

.rtf ul {
  list-style: disc;
}

.modal-container-footer {
  padding: 20px 32px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid #000;
  gap: 12px;
  position: relative;
}

.modal-container-footer:after {
  content: "";
  display: block;
  position: absolute;
  top: -51px;
  left: 24px;
  right: 24px;
  height: 50px;
  flex-shrink: 0;
  pointer-events: none;
}

.modal-box .button {
  padding: 12px 20px;
  border-radius: 8px;
  background-color: transparent;
  border: 0;
  font-weight: 600;
  cursor: pointer;
  transition: 0.15s ease;
  background-color: #9dc913;
  color: #ffffff;
}

.modal-box .button.is-ghost:hover, .button.is-ghost:focus {
  background-color: #9dc913;
}

.modal-box .button.is-primary {
  background-color: #5a4343;
  color: #fff;
}

.modal-box .icon-button {
  padding: 0;
  border: 0;
  background-color: #9dc913;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  cursor: pointer;
  border-radius: 8px;
  transition: 0.15s ease;
  color: #ffffff;
}

.modal-box .icon-button svg {
  width: 24px;
  height: 24px;
}
.questions{
  color: #000;
}
.questions label{
  font-size: 14px;
  text-align: justify;
  
}
.questions input{
  margin-right: 15px;
  
}
.questions p{
  text-align: justify;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
}
.sign-up .steps{
  display: flex;
  justify-content: center;
 position: relative;
  
}
.sign-up .step-area{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px 25px;
}
.sign-up .step-area span:nth-child(2){
 margin: 0 20px;
 font-size: 13px;
}
.sign-up .step-area .box{
  z-index: 999;
  background-color: #eaeaea;
  margin: 0 20px;
  width: 35px;
  height: 35px;
  line-height: 35px;
  border-radius: 50%;
  text-align: center;
  border: 1.3px solid #adacac;
  
}
.sign-up .steps .step-arrow{
  position: absolute;
  top: 42%;
  height: 3px;
  width: 100%;
  background-color: #c0bebe;
}
.sign-up .step-area .active-box{
  background-color: #9dc913;
  color: #ffffff;
  border: 1.5px solid #789a10;
}
.sign-up .intro-para{
  font-size: 13px;
  width: 80%;
  color: #898989;
  text-align: center;
  margin-bottom: 30px;
}
label p{
  font-size: 12px;
  font-weight: 300;
}
.highlight{
  color: #9dc913;
}
 @media screen and (max-width: 770px) {
      .sign-up .form,
      .objective-content.third{
        width: 80vw;
      }
    }
    @media (max-width: 600px) {
      .modal-container {
        width: 90%;
      }
      .sign-up .step-area{
        margin: 15px 0px;
      }
      .sign-up .step-area span:nth-child(2){
        margin: 0px;
      }
      .sign-up .step-area .box{
        width: 30px;
        height: 30px;
        line-height: 30px;
      }
      
    }
    
 @media screen and (max-width: 480px) {
      .sign-up .form,
      .objective-content.third{
        width: 90vw;
      }
      .sign-up .cut{
        width: 65%;
      }
      .sign-up .step-area .box{
        width: 25px;
        height: 25px;
        line-height: 25px;
      }
      .sign-up .title{
        font-size: 26px;
      }
      .sign-up .input{
        font-size: 16px;
      }
      .objective-content{
        width: 90vw;
      }
      .objective-content{
        padding: 30px 15px;
      }
      .third-container .score-label{
        width: 100%;
      }
      .modal-box .button.is-primary{
        padding: 12px 8px;
      }
    }
    .login{
      width: 100vw;
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
    }
     .login-container {
    max-width: 350px;
    background: #fff;
    border-radius: 10px;
    padding: 25px 35px;
    border: 5px solid rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin: 20px;
  }
  
 .login-container .heading {
    text-align: center;
    font-weight: 600;
    font-size: 30px;

  }
  
 .login-container .form {
    margin-top: 5px;
  }
  
 .login-container .form .input {
    width: 100%;
    border: none;
    padding: 12px 20px;
    border-radius: 5px;
    margin-top: 15px;
    border-inline: 2px solid transparent;
    border-bottom: 2px solid #cfe48e;
  }
  
 
 .login-container .form .input:focus {
    outline: none;
    border-inline: none;
  }
  
.login-container  .form .forgot-password {
    display: block;
    margin-top: 10px;
    margin-left: 10px;
  }
  
 .login-container .form .forgot-password a {
    font-size: 12px;
    color: #0099ff;
    text-decoration: none;
  }
  
.login-container  .form .login-button {
    display: block;
    width: 100%;
    font-weight: bold;
    background-color: #9dc913;
    color: white;
    padding-block: 5px;
    margin: 20px auto;
    border-radius: 20px;
    box-shadow: rgba(157, 201, 19, 0.8784313725) 0px 20px 10px -15px; 
    border: none;
    transition: all 0.2s ease-in-out;
  }
  
 .login-container .form .login-button:hover {
    transform: scale(1.03);
    box-shadow: rgba(157, 201, 19, 0.8784313725) 0px 23px 10px -20px;
  }
  
 .login-container .form .login-button:active {
    transform: scale(0.95);
    box-shadow: rgba(157, 201, 19, 0.8784313725) 0px 15px 10px -10px;
  }
  .submit-modal{
    background-color: rgba(0, 0, 0, 0.7);
    min-height: 100vh;
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* From Uiverse.io by Flowelife */ 
@keyframes load-loop {
  0% {
    background-position: 100%;
  }

  40% {
    background-position: 50%;
  }

  50% {
    background-position: 50%;
  }

  90% {
    background-position: 0%;
  }

  100% {
    background-position: 0%;
  }
}

.submit-loader {
  position: relative;
  font-family: sans-serif;
  display: flex;
  height: auto;
  width: auto;
  letter-spacing: 3px;
  align-items: center;
  justify-content: center;
  padding: 10px 12px;
  font-size: 1.45em;
  color: transparent;
  background: linear-gradient(to right,#fff 33.3%,#9dc913 33.3%, #9dc913 66.6%, #fff 66.6%) no-repeat;
  background-size: 300%;
  background-position: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  animation: infinite 1.4s load-loop ease;
}

.submit-loader::after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  display: block;
  background: linear-gradient(to right,#9dc913 33.3%, transparent 33.3% , transparent 66.6%, #9dc913 66.6%) no-repeat;
  background-size: 300%;
  background-position: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  animation: infinite 1.4s load-loop ease;
}