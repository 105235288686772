 .ReferUs{
    margin-top: 6rem;
 }
.ReferUs .referus1 .referushead {
    display: flex; 
    color: #ffffff;
    justify-content: space-around;
    align-items: center;
    border-bottom: 6px solid rgb(42, 202, 36);
    background-color: #585858;
}
 
.ReferUs .referus1 .container {
 
    width: auto;
    border-radius: 20px;
    
}
.ReferUs .referus1 .container .container1 button {
    background-color: rgb(157, 201, 19);
    font-weight: 500;
    color: white;
    width: 100%;
}
.ReferUs .referus1 .container .container1{
    border-radius: 10xp;
    box-shadow: 0px 0px 10px 0px rgb(216, 214, 214);
    padding: 20px;
}
.ReferUs .referus1 .container .container1 .mb-3{
    color:#c9c7c7;
}
.ReferUs .referus1 .container .container1 button:hover {
    background-color: white;
    color: black;
    outline: 2px solid rgb(157, 201, 19);
}

.ReferUs .referus1 .container .container2 {
    background-color: rgb(255, 255, 255);
    width: 50%;
    display: flex;
    flex-direction: column;

    justify-content: center;
}

.ReferUs .referus1 .container .container2 .ReferUsIcons {
    margin-top: 40px;
    height: 50px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.ReferUs .referus1 .container .container2 .ReferUsIcons i:hover{
    transform: scale(1.4);
    transition: 0.6s;
    cursor: pointer;
 
 

}
.ReferUs .referus1 .container .container2 button {
    background-color: rgb(157, 201, 19);
    font-weight: 500;
    color: white;
    width: 100%;
}

.ReferUs .referus1 .container .container2 button:hover {
    background-color: white;
    color: black;
    outline: 2px solid rgb(157, 201, 19);
}

@media screen and (max-width:1000px){
    .ReferUs .referus1 .container{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .ReferUs .referus1 .referushead{
        font-size: 20px;
        width: 100%;
        margin: 0 !important;
    
    }
    .ReferUs .referus1 .referushead h1{
        font-size: 30px;
    }
    .ReferUs .referus1 .container .container1{
        width: 95%;
    }
    .ReferUs .referus1 .container .container2{
        width: 95%;
    }
}

@media screen and (max-width:500px){
    .ReferUs .referus1 .referushead{
        height: 40vh;
        flex-direction: column;
        font-size: 50px;
        justify-content: space-around;
        align-items: center;
    }
}